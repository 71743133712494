$fieldrow: rgba(10, 18, 20, 0.2);

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.sidenav {
  .MuiDrawer-root {
    background-color: black !important;
    color: #fff !important
  }
}

.newshadow {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);

}

.dashboard-head {
  color: dimgray;
  margin-left: 20px;
  margin-top: 10px;
}

.dashboard-value {
  color: #000;
  margin-left: 20px;
  margin-top: 10px;
  font-size: 18px;
}

.user-table {
  // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow-x: auto;
  width: 100%;
  // font-weight: 600;

  table {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    width: 100%;
    margin-top: 20px;


    thead {
      background: #4d4d4e;
      // text-align: center !important;
      color: #fff;


      th {
        padding: 20px;
        text-align: center;
      }

      th:first-child {
        border-top-left-radius: 15px;
        text-align: center;

      }

      th:last-child {
        border-top-right-radius: 15px;
      }
    }

    tbody {
      // background: $tablebody;

      td {
        border-bottom: 0 !important;
        padding: 20px !important;
        text-align: center;
        font-weight: 400;

      }

      tr:nth-child(even) {
        background-color: #e8f0fe;
      }

      tr {
        &:first-child {
          // color: $grey;
          // border-bottom: solid 1px $borderColor;
          height: 70px !important;
        }

        .table-icon {
          border: 0;
          padding: 0;
          background-color: transparent;

          &:hover {
            background-color: transparent;
          }
        }
      }

      tr:nth-last-child(1) {
        td:first-child {
          // border-bottom-left-radius: 15px;

        }

        td:last-child {
          // border-bottom-right-radius: 15px;
        }
      }
    }
  }

}

.chat-container {
  // display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  // padding: 10px;
  // max-width: 80%; /* Adjust the width as needed */
  // // margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  // min-height: 600px;
  // border-radius: 5px;
  // position: relative;
}

.message {
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  background-color: #f1f0f0;
  clear: both;
  word-wrap: break-word;
}

.admin-message {
  float: right;
  background-color: #00a3e0;
  color: #fff;
}

html {
  scroll-behavior: smooth;
}

.background-blur {
  position: fixed;
  background: $fieldrow;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: #000;
}

.popup-new {
  width: 750px;
  background: #fff;
  margin-top: 110px;
  border-radius: 10px;
  margin-right: auto;
  margin-left: auto;
  height: 508px;
  padding: 10px;
  overflow: auto;
}